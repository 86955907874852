<template>
<v-container style="margin-top:60px;margin-bottom:160px;"  fluid>
  <!-- <v-img src="img/flash.png" style="position:absolute;top:-0px;left:0;right:0; margin:auto;z-index:1000;width:99%;"></v-img> -->
  <!-- <v-card class="rounded-lg" elevation="10" style="position:absolute;top:-0px;left:0;right:0; margin:auto;z-index:1000;width:99%;">
    <v-card-title><center><v-img src="img/flash.png" style="position:absolute;top:-0px;left:0;right:0; margin:auto;z-index:1000;width:99%;"></v-img></center></v-card-title>
  </v-card> -->

  <v-row>
        <v-col   cols="12" style="display: flex;    flex-direction: column;    align-items: center;    align-self: center;">
          <center><v-img src="img/robot_head_main.png" style="max-width: 106px;"></v-img></center></v-col>
       
  </v-row>

  <v-row>
        <v-col   cols="12" style="display: flex;    flex-direction: row;    align-items: center;    align-self: center;justify-content: center;">
          <span style="font-size:50px;">{{ points }}</span><br>
            <v-img src="img/ico_points.png" style="max-width: 51px;margin-left: 10px;"></v-img>
          </v-col>
       
  </v-row>


 

  <v-row align="center" justify="center" no-gutters style="    margin-top: 10px;    margin-bottom: 15px;">
    <v-col   cols="12" >
      <v-card style="width: 100%;border-radius: 20px; min-height: 260px;" class="transparent_to_background">
        <span style="padding: 15px;margin-top: 10px;" class="header_task">Завдання</span>
        <div class="carousel-wrapper">
          <div class="carousel" ref="slick">
            <!-- <div v-for="(item, i) in items" :key="i" class="carousel-item"> -->
            <div v-for="(card, i) in cards" :key="i" class="carousel-item">
               <v-card style="min-width: 255px; min-height: 170px;display: flex;"
               @click.native="
                     $router.push({
                     name: links[card['data']['type']],
                     params: { routeparam: card.id },
                     })
                  "> 
                  <v-row style="display: flex;    align-content: center;">
                    <v-col cols="7" style="text-align: center;">
                      <span >
                
                    {{ card["data"]["header"] }}<br>({{card['start_date']}}-{{card['end_date']}})
                    </span>
                    </v-col>
                    <v-col cols="5" style="min-width: 95px;">
                      <v-img v-if="card['data']['type'].includes('photo')&&!card['data']['type'].includes('dmx')" src="/img/tico_photo.png" alt="Slide Image" style="max-width: 95px;"></v-img>
                      <v-img v-if="card['data']['type'].includes('test')" src="/img/tico_question.png" alt="Slide Image" style="max-width: 95px;"></v-img>
                      <v-img v-if="card['data']['type'].includes('dmx')" src="/img/tico_dmx.png" alt="Slide Image" style="max-width: 95px;"></v-img>
                      
                    </v-col>
                    </v-row>
                <!-- <v-img :src="item.image" alt="Slide Image"></v-img> -->
               </v-card> 
            </div>
          </div>
        </div>
    </v-card>
    </v-col>
  </v-row>


  <v-row align="center" justify="center" no-gutters style="    margin-top: 10px;    margin-bottom: 15px;">
    <v-col   cols="12" >
      <v-card style="width: 100%;border-radius: 20px; min-height: 250px;" class="transparent_to_background">
        <span style="padding: 15px;margin-top: 10px;" class="header_task">Останні події</span>
        
    </v-card>
    </v-col>
  </v-row>

  

  <v-row align="center" justify="center" no-gutters style="    margin-top: 10px;    margin-bottom: 15px;">
    <v-col   cols="12" >
      <v-card style="width: 100%;border-radius: 20px; min-height: 250px;" class="transparent_to_background">
        <span style="padding: 15px;margin-top: 10px;" class="header_task">Новини</span>
        
    </v-card>
    </v-col>
  </v-row>

  

  <v-row align="center" justify="center" no-gutters style="    margin-top: 10px;    margin-bottom: 15px;">
    <v-col   cols="12" >
      <v-card style="width: 100%;border-radius: 20px; min-height: 250px;" class="transparent_to_background">
        <span style="padding: 15px;margin-top: 10px;" class="header_task">Інформація</span>
        <v-row>
          <v-col cols="7">
            <v-img  @click="$router.push({ name: 'C_info' })" src="/img/about_company.png" alt="Slide Image" style="max-width: 209px;"></v-img>
          </v-col>
          <v-col cols="5">
            <v-img   @click="$router.push({ name: 'News_pg' })" src="/img/planogram.png" alt="Slide Image" style="max-width: 171px;"></v-img>
          </v-col> 
        </v-row>
    </v-card>
    </v-col>
  </v-row>
  


      <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        ><btnsqr        
          img="img/ico_m_exit.png" 
           @click.native="logout()"
         
          >
          Вийти
        </btnsqr></v-col><v-col cols="1"></v-col>
    </v-row>





<v-dialog
        v-model="dialog_netw_confirm"
         style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Мережевий iTeam</v-card-title>
          <v-card-text>
             <v-row> <v-col>
              <p><b>  Запит очікує підтвердження модератором.</b></p>
              <p>Ви зможете продовжити реєстрацію та виконувати завдання після підтвердження приєднання точки до мережі.</p>
                </v-col>
             </v-row>
          </v-card-text>
          <v-card-actions>
            
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_netw_confirm = false"
            >
              Закрити
            </v-btn>
          </v-card-actions>
      </v-card>
</v-dialog>


<v-dialog
        v-model="dialog_task_confirm"
         style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Приєднання до iTeam</v-card-title>
          <v-card-text>
             <v-row> <v-col>
              <p><b>  Запит очікує підтвердження модератором.</b></p>
              <p>Ви зможете продовжити реєстрацію та виконувати завдання після підтвердження адміністраторами обраного типу точки.</p>
                </v-col>
             </v-row>
          </v-card-text>
          <v-card-actions>
            
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_task_confirm = false"
            >
              Закрити
            </v-btn>
          </v-card-actions>
      </v-card>
</v-dialog>

<v-dialog
        v-model="dialog_sku_confirm"
         style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Ассортимент iTeam</v-card-title>
          <v-card-text>
             <v-row> <v-col>
              <p><b>  Запит очікує підтвердження модератором.</b></p>
              <p>Ви зможете виконувати завдання після підтвердження.</p>
                </v-col>
             </v-row>
          </v-card-text>
          <v-card-actions>
            
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog_sku_confirm = false"
            >
              Закрити
            </v-btn>
          </v-card-actions>
      </v-card>
</v-dialog>

<v-dialog
        v-model="pass_change"
        persistent
        
        width="300" 
      >

      <v-form @submit.prevent="pass_set_new"> 
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Встановіть новий пароль</v-card-title>
          <v-card-text>
            <!-- <v-row fluid>
             
                <v-col cols="12">
                  <v-text-field
                    v-model="password_old"
                    name="Password1n"
                    label="Cтарий пароль"
                    solo
                    rounded
                    @click:append="show1 = !show1"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                  
                  ></v-text-field>
                </v-col>
               
              </v-row> -->
              <v-row fluid >
          <v-col cols="1"></v-col>
          <v-col cols="10">
            <center><a @click="pass_gen">Згенерувати надійний пароль</a></center>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
              <v-row fluid>
                
                <v-col cols="12">
                  <v-text-field
                    v-model="password_new"
                    name="new-password"
                    label="Новий пароль"
                    solo
                    rounded
                    @click:append="show3 = !show3"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"
                  ></v-text-field>
                </v-col>
                
              </v-row>
              <v-row fluid>
               
                <v-col cols="12">
                  <v-text-field
                    v-model="password_new_confirm"
                    name="confirm-new-password"
                    label="Підтвердження паролю"
                    solo
                    rounded
                    @click:append="show2 = !show2"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    autocomplete="new-password"
                  ></v-text-field>
                </v-col>
               
              </v-row>
           
            
          </v-card-text>
          <v-card-actions>
            
            <v-btn
              color="primary"
              text
              type="submit"
            >
              Встановити
            </v-btn>
            <!-- <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="pass_change = false"
            >
              Відмінити
            </v-btn> -->
          </v-card-actions>
      </v-card>
      </v-form>
</v-dialog>

<v-dialog
z-index="50000"
        v-model="cd_enter"
        persistent
        width="300" style="z-index:50000;"
      >
      <v-card class="rounded-lg" style="" elevation="10">
          <v-card-title style="background-color:orange;color:white;margin-bottom:15px;text-align:center;justify-content: center;">Вкажіть точку</v-card-title>
          <v-card-text>
             <v-row fluid>
                <v-col cols="1"></v-col>
                <v-col cols="10">
                  <v-text-field
            
                    v-model="pos_code"
                    name="pos_code"
                    label="С-"
                    solo
                    rounded
                    v-maska="'C-########'" 
                    inputmode="numeric"
                  ></v-text-field>
                </v-col>
               
              </v-row>
              <v-row>
                  <v-col cols="1"></v-col>
          <v-col cols="10">
            <v-radio-group v-model="tt_type">
              <v-radio label="Неконтрактна звичайна" value="no_contract_usual"></v-radio>
              <v-radio label="Неконтрактна мережева" value="no_contract_network"></v-radio>
              <v-radio label="TIP" value="tip"></v-radio>
              <v-radio label="KA" value="ka"></v-radio>
            </v-radio-group>
            <!-- <v-checkbox v-model="checkbox_is_network" color="#ee6424" class="ma-0 pa-0">
              <template v-slot:label>
                <div>
                  Позначте, якщо ваша ТТ мережева.
                </div>
              </template>
            </v-checkbox> -->
          </v-col>
          
        </v-row>
           
            
          </v-card-text>
          <v-card-actions>
            
            <v-btn
              color="primary"
              text
              @click="cd_set"
            >
              Зберегти
            </v-btn>
            <!-- <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="pass_change = false"
            >
              Відмінити
            </v-btn> -->
          </v-card-actions>
          <confirmdlg ref="confirm" />
      </v-card>
</v-dialog>
<!-- new_task_requirements_block_start -->
<v-dialog v-model="write_tp">
      <v-card>
        <v-card-title   style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_contact')}}  
        </v-card-title>
       
        <v-card-text>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
           <span v-html='tt_lock_reason'></span>
            </v-col>
          </v-row>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
             <v-textarea
            outlined
            name="input-7-4"
            label=""
            value=""
            v-model=req_tp_tp
          ></v-textarea>

          <v-btn primary large block type="submit" @click="save_request_unlock_tt"  class="primary_button">Надіслати</v-btn>
         </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="write_tp = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>



<v-dialog v-model="new_type_task_info">
      <v-card>
        <v-card-title   style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          Увага!  
        </v-card-title>
       
        <v-card-text>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
           <span >Дорогі партнери!<br>
На період військового стану вам буде надаватися можливість вибору рівня складності обов'язкових завдань. Кількість балів, що нараховується, залежить від обраного рівня. Обирати новий рівень складності можна кожні 2 тижні, а тип контракту при цьому залишається незмінним.
</span>
            </v-col>
          </v-row>
        
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="new_type_task_info = false;$router.push({name:'Tasks'})">  До завдань </span>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="da_banner">
      <v-card>
        
       
        
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="da_banner= false;">Закрити</span>
        </v-card-actions>
        <v-card-text style="padding:0 !important">
          <v-row align="top" justify="center">
         <v-col cols="12" >
         <v-img src="img/db.png" ></v-img>
            </v-col>
          </v-row>
        
        </v-card-text>
      </v-card>
    </v-dialog>
<!-- new_task_requirements_block_end -->

</v-container>
</template>



<script>

import axios from "axios";
import { API_LOCATION } from "../../config";
import confirmdlg from "../components/confirmdlg.vue";
import btnsqr from "../components/btnsqr.vue";
//import l_switch from "../components/lang_switch.vue";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import $ from 'jquery';
import 'slick-carousel';
export default {
  watch:{
    

    

  },
  components:{confirmdlg: confirmdlg,btnsqr},
  computed: {
    points: function () {
      return this.$store.getters.points;
    },
  },
  data: () => ({
    tt_type:'',
    da_banner:false,
    dialog_netw_confirm:false,
    dialog_task_confirm:false,
    dialog_sku_confirm:false,
    sku_lock:false,
    cd_enter:false,
    cd_lock:false,
    pass_lock:false,
    pos_code:'C-',
    network_lock:false,
    task_lock:false,
    deleted_lock:false,
    task_pending:0,
    menu_active:true,
    pass_change:false,
    password_old:null,
    password_new:null,
    password_new_confirm:null,
    show1: false,
    show2: false,
    show3: false,
     g:{long:'',lat:'',coord_state:''},
    write_tp:false,
    req_tp_tp:'',
    tt_lock_reason:'',
    new_type_task_info:false,
    ignore_err:false,
    items : [  { image: '/img/tzap.png' }, 
    { image: '/img/tzap.png' },  { image: '/img/tzap.png' }, 
    { image: '/img/tzap.png' },  { image: '/img/tzap.png' }, 
    ],
    cards: [],
    links: {
      sku_photo: "Phototask",
      lay_photo: "Phototask",
      mlevel_sku_photo: "Phototask",
      mlevel_sku_photo_dmx: "Phototask_dmx",
      mlevel_sku_photo_dmx_multi: "Phototask_dmx",
      mlevel_lay_photo: "Phototask",
      
      ex_photo: "Phototask",
      ex_photo_code: "Phototask",
      ex_photo_timer: "Phototask",
      ex_photo_dmx: "Phototask_dmx",
      ex_photo_dmx_multi: "Phototask_dmx",
      custom_extra_photo: "Phototask",
      test: "Testtask",

      ka_main:"Ka_main",

    },
    
  }), 
  methods: {
    pass_gen: function(){
      var chars = "0123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ";
      var passwordLength = 8;
      var password = "";
      for (var i = 0; i <= passwordLength; i++) {
        var randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber +1);
      }
      if(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/.test(password)==false) { this.pass_gen(); }
      else {
        this.password_new=password
        this.password_new_confirm=password
        this.show1=true
        this.show2=true
        this.show3=true
        console.log(password)
        }
    },
    save_request_unlock_tt: function(){

            axios.post(API_LOCATION,  { data: 'component_data',action: 'save_request_unlock_tt', 'question':this.req_tp_tp },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{
           
         if(resp.data=='OK'){ 
            //this.$store.commit('setSnack', 'Запит надіслано!')
            
            this.$emit('pushe', {header:'Повідомлення',text:'Запит надіслано!'});
            this.write_tp=false;
            this.req_tp_tp='';
            //this.$router.push({ name: 'Profile'})
          }
          })
            
        },
    navOnSuccess(position) {
                 console.log('Latitude: '          + position.coords.latitude          + '\n' +
                        'Longitude: '         + position.coords.longitude         + '\n' +
                        'Altitude: '          + position.coords.altitude          + '\n' +
                        'Accuracy: '          + position.coords.accuracy          + '\n' +
                        'Altitude Accuracy: ' + position.coords.altitudeAccuracy  + '\n' +
                        'Heading: '           + position.coords.heading           + '\n' +
                        'Speed: '             + position.coords.speed             + '\n' +
                        'Timestamp: '         + position.timestamp                + '\n');
                        
                        
                        this.g.long = position.coords.longitude
                        this.g.lat = position.coords.latitude
                        this.g.coord_state='success'
                        console.log('long: '+this.g.long+': lat:'+this.g.lat)
                        //alert('long: '+this.g.long+': lat:'+this.g.lat)
                        console.log(this.g);

              },

              navOnError(error) {
                  console.log('NAV_FAIL: code: '    + error.code    + '\n' +
                        'message: ' + error.message + '\n')
              },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },

    task_go:function(){
    
            axios
      .post(
        API_LOCATION,
        {
          
          action: "task_go"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data);
        if(resp.data.state=='tt_check_messsage'){
            if(resp.data.type==1){
              this.tt_lock_reason='За нашими даними, ваша торгова точка знаходиться на тимчасово окупованій території. Доступ до розділу «Завдання», на жаль, для вас тимчасово обмежений. Але ми дуже чекаємо на ваше повернення до програми!<br>Повідомте нас, будь ласка, якщо у вас збереглася можливість подальшої співпраці.'
              this.write_tp=true;
              return;
            }
            if(resp.data.type==3){
              this.tt_lock_reason='Ваша торгова точка знаходиться в списку точок, які тимчасово не можуть брати участь у програмі у зв’язку зі складною ситуацією в регіоні. Але ми дуже чекаємо на ваше повернення до програми!<br>Повідомте нас, будь ласка, якщо у вас збереглася можливість подальшої співпраці.'
              this.write_tp=true;
              return;
            }
            if(resp.data.type==9||resp.data.type==10){
              this.$emit('pushe', {header:'Повідомлення',text:'Ваш запит відправлено на опрацювання. При розблокуванні торгової точки, надійде відповідне інформаційне sms-повідомлення.'});
              return;
            }
        }
        /*
        else if(resp.data.state=='temp_task')
        {
          this.$router.push("/asssel_temp");
        }
        else if(resp.data.state=='new_type_task_info')
        {
          this.new_type_task_info=true;
        }*/
        else if(resp.data.state=='go')
        {
               
           this.$router.push("/Tasks");    
        }

      });


    
  },

    da_bannerc:function(){
    
            axios
      .post(
        API_LOCATION,
        {
          
          action: "da_banner"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        if(resp.data.state=='da_banner'){
          this.da_banner=true;
        }
        

      });


    
  },

    pass_set_new:function(){
      
        this.show2=false
            axios
      .post(
        API_LOCATION,
        {
          
          action: "pass_set_new",
          pass_old:this.password_old,
          pass_new1: this.password_new,
          pass_new2: this.password_new_confirm,
          temp:true
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data);
        if(resp.data.pass_set!='ok'&&resp.data.debug!='on')
        {
          alert(this.$t(resp.data.error));
        }
        else
        {
          alert(this.$t('success_change'));
          this.pass_change=false;
          this.logout();

        }
      });


    
  },
  
  
  
  
  cd_set: async function(){
        await    axios
      .post(
        API_LOCATION,
        {
          
          action: "cd_update",
          pos_code: this.pos_code,
          tt_type: this.tt_type,
          
          ignore_err:this.ignore_err
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then(async (resp) => {



        console.log(resp.data);
        if(!this.ignore_err&&resp.data.reg_final!='ok'&&resp.data.debug!='on'&&resp.data.error!='no tip'&&resp.data.error!='no iteam'&&resp.data.error!='no ka')
        {         
          alert(this.$t(resp.data.error));
        } 
        else
        {
          console.log('in first else (almoust done)');

          if(!this.ignore_err&&(resp.data.error=='no tip'||resp.data.error=='no iteam'||resp.data.error=='no ka')){
            console.log('in second else (call confirm)');


            if (        await this.$refs.confirm.open("Підтвердження", this.$t(resp.data.error)))
              { 
                this.ignore_err=true
                this.cd_set()
              

          } else { console.log('canceled')}

          


          }
          else{
            this.cd_enter=false;
                this.cd_lock=false;
                if(!this.cd_lock&&!this.pass_lock) { this.menu_active=true;}
          }



        }








      
        // console.log(resp.data);
        // if(resp.data.reg_final!='ok'&&resp.data.debug!='on')
        // {
        //   alert(this.$t(resp.data.error)+'\n'+resp.data.tt_data);
        // }
        // else
        // {
               
        //         this.cd_enter=false;
        //         this.cd_lock=false;
        //         if(!this.cd_lock&&!this.pass_lock) { this.menu_active=true;}
        // }
      });


    
  },
   get_cd_exist: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "is_cd_exist"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!CD'+resp.data);
              if(resp.data==false)         {
                this.menu_active=false;
                this.cd_enter=true;
                this.cd_lock=true;
              } 
              
        
      });

    },
    get_temp: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "is_temp_pass"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==1)         {
                this.menu_active=false;
                this.pass_change=true;
                this.pass_lock=true;
              } 
              
        
      });

    },
    get_k_type: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "k_type"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==9)         {
                this.$router.push("/Asssel");
              } 
              else if(resp.data==10)     {
                 this.$router.push("/Asssel");
              }
        
      });

    },
    get_pending_task: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "pending_task"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              this.task_pending=resp.data;
              console.log('PENDING:'+resp.data);
              
        
      });

    },
    get_pending_network_confirm: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "is_network_pending"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==1)         {
                this.dialog_netw_confirm=true;
                this.network_lock=true
              }
        
      });

    },
    get_pending_task_confirm: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "is_task_pending"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==1)         {
                this.dialog_task_confirm=true;
                this.network_lock=true
              }
        
      });

    },
    get_pending_sku_confirm: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "is_sku_pending"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==1)         {
                this.dialog_sku_confirm=true;
                this.sku_lock=true;
              }
        
      });

    },
    get_pending_profile: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_pending_profile"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
            if(resp.data==0)
            {
            this.$router.push("/Profile");
            }
            console.log('PROFILE',resp.data)
        
      });

    },
    get_pending_sku_change: function(){

      axios
      .post(
        API_LOCATION,
        {
          
          action: "sku_change"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
            if(resp.data==1)
            {
            this.$router.push({
                     name: 'Asssel',
                     params: { change_type: 1 },
                     })
            }
           
        
      });

    },
    get_pending_reg_complete: function(){

    },

    initializeSlick() {
      this.$nextTick(() => {
        $(this.$refs.slick).slick({
      dots: true,
      arrows: false,  // Disable arrow controls
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      //centerMode: true,  // Show part of the next slide
      variableWidth: true, // Allow cards of different widths to appear properly
     
      // eslint-disable-next-line no-unused-vars
      customPaging: function(slider, i) {
        return '<div class="custom-dot"></div>';
      }
    });
      });
    }


    
  },
  mounted() {
    //task_slider_start

    

    //task_slider_end

    axios
      .post(
        API_LOCATION,
        {
          data: "component_data",
          action: "get_tasks",
          filter: this.$route.params.routeparam,
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
        // eslint-disable-next-line no-unused-vars
        resp.data.forEach((element) => {
         
          

          this.cards.push({
            id: element.id,
            type: element.type,
            start_date: element.start_date,
            end_date: element.end_date,
            data: JSON.parse(element.data_json),
            ex: element.id_ex,
            id_ex_previous: element.id_ex_previous,
            correct: element.correct,
            moderate: element.moderate,
            
          });
          console.log(element.moderate!='1'&&element.correct=='1');
        });
        this.overlay_main = false;
        console.log(resp.data);
        //console.log(this.cards);
        this.initializeSlick();
      });

  },
  created() {

    this.get_cd_exist();
    this.get_temp();
    this.get_pending_task();
    this.get_pending_profile();
    this.get_pending_sku_change();
    this.da_bannerc();
          if (navigator.geolocation)
              {
              navigator.geolocation.getCurrentPosition(this.navOnSuccess, this.navOnError,{enableHighAccuracy:false,maximumAge:Infinity, timeout:60000})
               console.log('start_nav_detect')
              }
              else
              {
                console.log('geolocation_not_supported')
              }

    this.$store.dispatch("profileset");
    
    this.$store.dispatch("page_title_set", "Головна");  
    
    axios
      .post(
        API_LOCATION,
        {
          
          action: "api_check"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              console.log(resp.data);         
        
      });
    this.get_k_type();
    this.get_pending_sku_confirm();
    this.get_pending_network_confirm();
    this.get_pending_task_confirm();









  },
};
</script>


<i18n>
{
  "ua": {
    "h_contact":"Доступ обмежено",
    "wrong_old":"Помилковий старий пароль",
"pass not match":"Паролі не співпадають",
"pass insecure":"Пароль має містити від 8 символів, серед яких має бути мінімум 1 цифра, 1 велика літера, 1 маленька літера.",
    "success_change":"Пароль успішно змінено, увійдіть до додатку з новим паролем",
"close":"Закрити",
 "check_pos":"Вказана точка відсутня в базі",
 "no type":"Оберіть тип торгової точки, щоб продовжити реєстрацію",
 "no ka":"У базі відсутня відмітка \"KA\" для вказаної точки. Користувач зможе продовжити реєстрацію лише після погодження Адміна. ",
 "no tip":"У базі відсутня відмітка \"TIP\" для вказаної точки. Користувач зможе продовжити реєстрацію лише після погодження Адміна.",
 "no iteam":"У базі торгова точка має статус контрактної. Користувач зможе продовжити реєстрацію лише після погодження Адміна. ",

 "check_pos 2 users exist":"На торговій точці вже зареєстрована максимальна кількість продавців."

  }
}
</i18n>

<style >
.carousel-item {
  padding: 0 10px;
  width: 300px; /* Ensure each card has a fixed width */
}

.slick-dots {
  bottom: -10px; /* Adjust the position of dots */
}

.slick-dots li button {
  display: none; /* Hide the default buttons inside the dots */
}

.slick-dots li {
  margin: 0 5px;
}

.slick-dots li::before {
  content: '';
}

.custom-dot {
  width: 12px;
  height: 12px;
  background-color: orange; /* Custom orange dots */
  border-radius: 50%;
  cursor: pointer;
}

.slick-dots .slick-active .custom-dot {
  background-color: darkorange; /* Active dot styling */
}
</style>